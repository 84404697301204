import {
  ChakraProvider,
} from "@chakra-ui/react"
import { indexChakraTheme } from "./styles/themeConfig"
import { Main } from "./router"
import { BrowserRouter } from "react-router-dom"

export const App = () => (
  <ChakraProvider theme={indexChakraTheme}>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </ChakraProvider>
)
