import { Route } from "react-router-dom";
import { ImportCSV } from "../../views/Csv/ImportCSV";
import { paths } from "../path";
import PrivateRoute from "../privateRoute";

const routes = [
  <Route path={paths.CALENDAR_CSV} element={<PrivateRoute />} children={[
    <Route path={paths.CALENDAR_CSV} element={<ImportCSV />} />,
  ]} />,
]

export default routes