import { Button, HStack, Stack, useToast, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Field, Form } from "react-final-form"
import { useDispatch, useSelector } from "react-redux"
import { InputSelect } from "../../components/form"
import { InputTextarea } from "../../components/form/InputTextarea"
import { selectors } from "../../store/selectors"
import { actions } from "../../store"
import addTicket from "../../services/api/tickets/addTicket"
import { CopyIcon } from "@chakra-ui/icons"

export const AddTicket = (props) => {

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined,
      );

  const required = (value) => ((value && value !== 'choisir') ? undefined : "Champ requis");

  const dispatch = useDispatch()
  const ticketCategories = useSelector(selectors.ticketCategories.getSelector)

  const client = useSelector(selectors.client.getSelector)

  const [loading, setLoading] = useState(false);

  const formatOptions = (data) => {
    if(!data) return []
    return data.map((e) => ({
      value: e.uuid,
      name: e.title,
    }))
  }

  const toast = useToast({
    variant: "toast_success",
  })

  function optionCalculator(category) {
    if(!category) return []
    const subCategories = ticketCategories.find((e) => e.uuid === category)?.subCategories
    if(subCategories) return formatOptions(subCategories)
    return []
  }


  useEffect(() => {
    dispatch(actions.ticketCategories.get.process())
  }, [dispatch])

  return (
    <>
      <VStack spacing={8} width='100%'>
        <Form
          onSubmit={async (values) => {
            try {
              setLoading(true)
              const tokens = JSON.parse(localStorage.getItem('tokens'))
              await addTicket({ data: { category: values['subCategory'], userUuid: client?.uuid, content: values['content'] }, headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
            } catch (e) {
              console.log(e)
            }
            setLoading(false)
            props.submitCallBack()
          }}
          render={({ handleSubmit, values }) => (


            loading ? (<h1>Chargement...</h1>) : (
              <form onSubmit={handleSubmit} style={{width: '80%'}}>
                <Stack spacing={4}>
                  <Field
                    name="category"
                    component={InputSelect}
                    label="Catégorie"
                    options={formatOptions(ticketCategories)}
                    validate={composeValidators(required)}
                  />
                  <HStack>
                    <Field
                      name="subCategory"
                      component={InputSelect}
                      label="Sous-catégorie"
                      options={optionCalculator(values.category)} 
                      validate={composeValidators(required)}
                    />
                    {values.subCategory && 
                      <Button
                        ml={2}
                        mb={1}
                        style={{marginTop: '30px', borderRadius: '10px'}}
                        onClick={() => {
                          navigator.clipboard.writeText(`${values.subCategory}`)
                          toast({
                            title: 'UUID copié !',
                            status: 'success',
                            duration: 1000,
                            isClosable: true,
                          })
                        }}
                      >
                        <CopyIcon color='primary'/>
                      </Button>
                    }
                  </HStack>
                  <Field
                    name="content"
                    component={InputTextarea}
                    label="Objet"
                    initialValue={''}
                    validate={composeValidators(required)}
                  />
                  <Stack>
                    <Button
                      type="submit"
                      colorScheme='primary'
                      size='md'
                      style={{width: '200px'}}
                    >
                      Ajouter la demande
                    </Button>
                  </Stack>
                  
                </Stack>
              </form>
            )
          )}
        />
      </VStack>
    </>
  )
}
