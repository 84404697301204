import { Box, Button, Grid, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, VStack, IconButton } from "@chakra-ui/react"
import { IndexLogo } from "../../components/logo"
import { ImportCSV } from "../Csv/ImportCSV"
import { Navigate, useNavigate } from "react-router-dom"
import { MdExitToApp} from "react-icons/md"

export const WaitingPage = () => {
  const uploadCSVModalDisclosure = useDisclosure()
  const navigate = useNavigate();

  const handleClearTokens = () => {
    localStorage.removeItem('tokens');
    navigate('/auth/login');
  }

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3} >
        <VStack>
          <Box width={"100%"} display='flex' height={50} paddingLeft={1} paddingTop={1} flexDirection={"row"} justifyContent={"flex-start"}>
              <>
                <IconButton
                  aria-label='GoHome'
                  variant='solid'
                  icon={<MdExitToApp />}
                  fontSize='3 0px'
                  size='lg'
                  onClick={handleClearTokens}
                />
              </>
            </Box>
            <Box width={"100%"} display='flex' flexDirection={'column'} justifyContent={'center'} alignItems={'center'} pt={80} gap={4}>
              <IndexLogo width={'100%'} height={'100px'}/>
              {/*<Button colorScheme='blue' width={500} onClick={uploadCSVModalDisclosure.onOpen}>Enregistrer des rendez-vous (csv)</Button>
              <Modal isOpen={uploadCSVModalDisclosure.isOpen} onClose={uploadCSVModalDisclosure.onClose} size='full'>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Ajouter des rendez-vous</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <ImportCSV/>
                  </ModalBody>

                  <ModalFooter>
                    <Button colorScheme='primary' mr={3} onClick={uploadCSVModalDisclosure.onClose}>Fermer</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>*/}
              <Button colorScheme='blue' width={500} onClick={()=>{navigate("/clients/")}}>Répertoire utilisateur</Button>
              <Button colorScheme='blue' width={500} onClick={()=>{navigate("/calls/")}}>Historique des appels</Button>
            </Box>
        </VStack>
      </Grid>
    </Box>
  )
}