import {  Box, Stack, VStack } from "@chakra-ui/react"
import { Field, Form } from "react-final-form"
import { useSelector } from "react-redux"
import { InputText } from "../../components/form"
import { selectors } from "../../store/selectors"

export const ClientPersonnalInfo = () => {
  const client = useSelector(selectors.client.getSelector)

  return (
    <>
      <VStack spacing={8} width='100%'>
        <Form
          onSubmit={(values) => {}}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <Box
                  as="h3"
                  textStyle="h3"
                  fontWeight={700}
                  color="primary.400"
                  textAlign="left"
                  fontSize={12}
                  mt={3}
                  mb={3}
                >
                  INFOS PERSONELLES{' '}  
                </Box>
                <Field
                  name="lastname"
                  component={InputText}
                  label="Nom"
                  initialValue={client?.lastName ? client.lastName : ''}
                  disabled
                />
                <Field
                  name="firstname"
                  component={InputText}
                  label="Prénom"
                  initialValue={client?.firstName ? client.firstName : ''}
                  disabled
                />
                <Field
                  name="phone"
                  component={InputText}
                  label="Téléphone"
                  initialValue={client?.phoneNumber ? client.phoneNumber : ''}
                  disabled
                />
                <Field
                  name="mail"
                  component={InputText}
                  label="E-Mail"
                  initialValue={client?.email ? client.email : ''}
                  disabled
                />
                <Box
                  as="h3"
                  textStyle="h3"
                  fontWeight={700}
                  color="primary.400"
                  textAlign="left"
                  fontSize={12}
                  mt={3}
                  mb={3}
                >
                  ADRESSE{' '}  
                </Box>
                <Field
                  name="street"
                  component={InputText}
                  label="Nom et numéro de rue"
                  initialValue={client?.street ? client.street : ''}
                  disabled
                />
                <Field
                  name="postCode"
                  component={InputText}
                  label="Code postal"
                  initialValue={client?.postCode ? client.postCode : ''}
                  disabled
                />
                <Field
                  name="city"
                  component={InputText}
                  label="Ville"
                  initialValue={client?.city ? client.city : ''}
                  disabled
                />

                <Box
                  as="h3"
                  textStyle="h3"
                  fontWeight={700}
                  color="primary.400"
                  textAlign="left"
                  fontSize={12}
                  mt={3}
                  mb={3}
                >
                  ABONNEMENT{' '}  
                </Box>
                <Field
                  name="subscribeType"
                  component={InputText}
                  label="Type d'abonnement"
                  initialValue={client?.role ? client.role : ''}
                  disabled
                />
              </Stack>
            </form>
          )}
        />
      </VStack>
    </>
  )
}
