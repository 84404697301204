import { paths as clients } from './clients/path'
import { paths as home } from './home/path'
import { paths as auth } from './auth/path'
import { paths as calendar } from './calendar/path'
import { paths as calls } from './calls/path'

export const paths = {
  ...home,
  ...clients,
  ...auth,
  ...calendar,
  ...calls,
}
