import { Route } from "react-router-dom";
import { AuthCallback } from "../../views/auth/Callback";
import { Login } from "../../views/auth/Login";
import { paths } from "../path";

const routes = [
  <Route path={paths.AUTH_CALLBACK} element={<AuthCallback/>} />,
  <Route path={paths.AUTH_LOGIN} element={<Login/>} />,
]

export default routes