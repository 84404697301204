import { request } from '../../http'

const req = async ({ data, headers }) => 
  request({
    route: `/auth/call-center-login-url/`,
    method: 'GET',
    headers,
    dataType: 'json',
    data: {},
  })

export default req

