import { request } from '../../http'

const req = async ({ data, headers }) =>
  request({
    route: '/config',
    method: 'GET',
    headers,
    dataType: 'json',
    data: {}
  })

export default req
  
