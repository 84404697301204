import { Box, Button, Grid, VStack, Stack, Divider, ListItem, UnorderedList } from "@chakra-ui/react"
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { InputFile } from "../../components/form/InputFile";
import { FaDownload } from "../../components/Svg";
import uploadCsv from "../../services/api/calendar/uploadCsv";
import { useToast } from '@chakra-ui/react'

export const ImportCSV = () => {

  const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

  const required = (value) => ((value && value !== 'choisir') ? undefined : "Champ requis");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([])
  const toast = useToast()

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" bg="white">
        <VStack>
          <h3>
            Notice
          </h3>
          <p style={
            {
              fontWeight: '100',
              fontSize: '15px',
              textAlign: 'justify',
              width: '80%',
            }
          }>
            Vous pouvez ici envoyer un fichier CSV contenant des rendez-vous.
            Vous pouvez télécharger un modèle de fichier CSV en cliquant sur le bouton ci-dessous.
          </p>
          <Button colorScheme='primary' size='md' onClick={
            () => {
              window.open('/CSVExempleIndexRDV.csv', '_blank')
            }
          }>
            <FaDownload style={{marginRight: '10px'}}
            />
            Télécharger le modèle
          </Button>
          <Divider style={
            {
              marginTop: '30px',
              marginBottom: '30px',
            }
          }/>
          <h3>
            Importer des rendez-vous
          </h3>
          <Form
            onSubmit={async (values) => {
              let res = null;
              try {
                setLoading(true)
                const tokens = JSON.parse(localStorage.getItem('tokens'))
                setErrors([])
                res = await uploadCsv({ data: {
                  file: values.file,
                  validationOnly: false,
                }, headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
                toast({
                  title: 'Rendez-vous importés !',
                  description: `${res} rendez-vous ont été importés.`,
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                })
              } catch (e) {
                // If code 400 => display error message
                if(e.status === 400) {
                  setErrors(e.data?.message)
                } else {
                  setErrors(['Une erreur est survenue'])
                }
              }
              setLoading(false)
              // reset form
              values.file = null

            }}
            render={({ handleSubmit, values }) => (
              loading ? (<h1>Chargement...</h1>) : (
                <form onSubmit={handleSubmit} style={{width: '80%'}}>
                  <Stack spacing={4}>
                    <Field
                      name="file"
                      component={InputFile}
                      label="Fichier CSV"
                      validate={composeValidators(required)}
                    />
                    <Stack>
                      <Button
                        type="submit"
                        colorScheme='primary'
                        size='md'
                        style={{width: '200px'}}
                      >
                        Envoyer le fichier
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              )
            )}
          />
          <UnorderedList>
          {errors.map((e) => (
            <ListItem style={
              {
                color: 'red',
                fontSize: '15px',
              }
            }>{e}</ListItem>
          ))}
          </UnorderedList>
        </VStack>
      </Grid>
    </Box>
  )
}