import { ArrowBackIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, EditIcon } from "@chakra-ui/icons"
import { Box, Button, Grid, HStack, Icon, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, useDisclosure, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { MdHome } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { IndexLogo } from "../../components/logo"
import { actions } from "../../store"
import { selectors } from "../../store/selectors"
import { Client } from "./Client"

export const ClientList = () => {

  const dispatch = useDispatch()
  const indexConfig = useSelector(selectors.indexConfig.getSelector)
  const clients = useSelector(selectors.clients.getSelector)
  const navigate = useNavigate()
  const [getSearch, setSearch] = useState('');
  const [getPage, setPage] = useState(1);
  const [getLastPage, setLastPage] = useState(1);

  const uploadUserModalDiscolure = useDisclosure()
  const [getSelectedClientUuid, setSelectedClientUuid] = useState('');
  

  useEffect(() => {
    dispatch(actions.clients.get.process({
      page: getPage,
      search: getSearch
    }))
  }, [dispatch, getSearch, getPage])
  
  useEffect(() => {
    if(clients?.meta?.totalPages) {
      setLastPage(Number(clients?.meta?.totalPages))
    }
  }, [clients])

  function handleSearchChange(e) {
    setSearch(e.target.value);
  }

  function getPreviousPage() {
    if(getPage === 1) {
      return
    }
    setPage(getPage - 1)
  }

  function getNextPage() {
    if(getPage === getLastPage) {
      return
    }
    setPage(getPage + 1)
  }

  return (
    <>
      <Box textAlign="center" fontSize="xl">
        <Modal isOpen={uploadUserModalDiscolure.isOpen} onClose={uploadUserModalDiscolure.onClose} size='full'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Utilisateur</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Client uuid={getSelectedClientUuid} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Grid minH="100vh" p={3} bg='white'>
          <VStack>
            <Box width={"100%"} display='flex' height={50} paddingLeft={1} paddingTop={1} flexDirection={"row"} justifyContent={"flex-start"}>
              <>
                <IconButton
                  aria-label='GoHome'
                  variant='solid'
                  icon={<MdHome/>}
                  fontSize='3 0px'
                  size='lg'
                  onClick={()=>{navigate("/")}}
                />
              </>
            </Box>
            <Input placeholder='Recherche' size='md' width={500} marginTop={50} marginBottom={50} value={getSearch} onChange={(e) => handleSearchChange(e)}/>
            <TableContainer width={'70%'}>
              <Table size='sm' >
                <Thead>
                  <Tr>
                    <Th>Prénom</Th>
                    <Th>Nom</Th>
                    <Th isNumeric>Téléphone</Th>
                    <Th>Voir</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {clients && clients.data && clients.data.map((client) => {
                    return (
                      <Tr>
                        <Td>{client.firstName}</Td>
                        <Td>{client.lastName}</Td>
                        <Td isNumeric>{client.phoneNumber}</Td>
                        <Td><Button onClick={()=>{
                          setSelectedClientUuid(client.uuid);
                          uploadUserModalDiscolure.onOpen();
                        }}><EditIcon /></Button></Td>
                      </Tr>
                    )
                  })} 
                </Tbody>
              </Table>
            </TableContainer>
            <div>
              <Button colorScheme='primary' padding={0} margin={0} borderRadius={0} borderLeftRadius={10} disabled={getPage === 1} onClick={getPreviousPage}><ChevronLeftIcon/></Button>
              <Button colorScheme='primary' padding={0} borderRadius={0}>{getPage}/{getLastPage}</Button>
              <Button colorScheme='primary' padding={0} borderRadius={0} borderRightRadius={10} disabled={getPage === getLastPage} onClick={getNextPage}><ChevronRightIcon/></Button>
            </div>
          </VStack>
        </Grid>
      </Box>
    </>
  )
}