import axios, { ResponseType } from 'axios'
import transform from './transform'
import HTTPError from './HTTPError'
import { Type } from 'typescript';
//import { getConfig } from '../../config'

export { default as dataTypes } from './dataTypes'

class requestDto {
  route!: string;
  method: 'GET'|'POST'|'PUT'|'DELETE';
  headers: any;
  data: any;
  dataType: string;
  params?: any;
  withCredentials?: boolean;
  responseType?: ResponseType;
}

export const request = async({
  route,
  method,
  headers,
  data,
  dataType,
  params,
  withCredentials = false,
  responseType = 'json',
}: requestDto) => {
  try {
    const transformRequest = [transform(dataType)]
    const response = await axios.request({
      baseURL: process.env.REACT_APP_API_URL,
      url: route,
      method,
      headers,
      data,
      transformRequest,
      params,
      withCredentials,
      responseType,
    })
    return response.data
  } catch (err) {
    if (err.response) {
      if(err.response.status === 401) {
        // TODO : Imrpove this
      }
      throw new HTTPError(err.response.status, err.response.data)
    } else if (err.request) {
      throw new Error(
        'Something wrong (spotty network, cross-domain request not authorized, etc.)'
      )
    } else {
      throw err
    }
  }
}
