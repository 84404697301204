import { select } from 'redux-saga/effects'
import { selectors } from '../../selectors'
function* createDefaultHeaders(isAuthenticated) {
  let headers = {}
  if (isAuthenticated) {
    const tokens = JSON.parse(localStorage.getItem('tokens'))
    //console.log(tokens)
    headers = {
      Authorization: `Bearer ${tokens?.accessToken}`,
    }
  }
  return headers
}

export default createDefaultHeaders
