import * as React from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Textarea,
} from '@chakra-ui/react'

export const InputTextarea = ({
  files,
  input,
  meta,
  onChange,
  invalid,
  isFacultatif,
  maxLength,
  LeftIcon,
  RightIcon,
  disabled = false,
  ...rest
}) => (
  <FormControl variant="floating">
    <InputGroup>
      {LeftIcon && (
        <InputLeftElement>
          <LeftIcon />
        </InputLeftElement>
      )}
      <Textarea
        {...input}
        placeholder={rest.placeholder || ' '}
        isInvalid={(meta.error || meta.submitError) && meta.touched}
        maxLength={maxLength || null}
        borderColor="#F4F0E3"
        disabled={disabled}
        _invalid={{
          backgroundColor: '#DC3F1A14',
          color: '#363537',
          borderColor: 'red.400',
          borderWidth: '1.5px',
        }}
      />

      {rest.label && (
        <FormLabel>
          {rest.label}{' '}
          <span style={{ fontStyle: 'italic' }}>
            {isFacultatif && '(facultatif)'}
          </span>
        </FormLabel>
      )}
      {RightIcon && (
        <InputRightElement>
          <RightIcon />
        </InputRightElement>
      )}
    </InputGroup>
    {(meta.error || meta.submitError) && meta.touched && (
      <Box as="p" fontWeight="700" color="red.400" textAlign="left" mt={3}>
        {meta.error || meta.submitError}
      </Box>
    )}
  </FormControl>
)
