import api from '../../../services/api'
import { sagas } from '../../utils'
import { get as actions } from '../actions'

export default () =>
  sagas.createDefaultSaga({
    apiCall: api.client.getClient,
    actions,
    payloadKey: 'get',
  })
