import { dataTypes, request } from '../../http'

const req = async ({ data, headers }) => 
  request({
    route: '/call-center/tickets/csv/',
    method: 'POST',
    headers,
    dataType: dataTypes.FORM_DATA,
    data,
  })

export default req
  
