import { Badge, Box, Button, Grid, HStack, IconButton, Image, Tab, TabList, TabPanel, TabPanels, Tabs, useToast, VStack } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectors } from "../../store/selectors"
import { ClientPersonnalInfo } from "./ClientPersonnalInfo"
import { ClientTickets } from "./ClientTickets"
import { actions } from "../../store"
import { CopyIcon, PhoneIcon } from "@chakra-ui/icons"
//import callClient from "../../services/api/client/callClient"


export const Client = ({location, uuid}: {location?: string; uuid: string}) => {

  const dispatch = useDispatch()
  const client = useSelector(selectors.client.getSelector)
  const toast = useToast({
    variant: "toast_success",
  })

  /*async function call(clientUuid: string) {
    try {
      // TODO : Add loading and modal
      const tokens = JSON.parse(localStorage.getItem('tokens'))
      await callClient({ data: { uuid: clientUuid }, headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
    } catch (e) {
      console.log(e)
    }
  }*/

  useEffect(() => {
    dispatch(actions.client.get.process({
      uuid
    }))
  }, [dispatch, uuid])

  return (
    <>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3} bg='white'>
        <VStack>
          <VStack>
            <Box display="flex" justifyContent="center">
              <Image
                borderRadius="full"
                boxSize={100}
                bg="white"
                borderColor="primary.400"
                src={client?.profilePicture ? client.profilePicture.thumbnailSizeUrl : ''}
                alt={`Pas de photo`}
              />

            </Box>
            <h2 style={
              {
                fontWeight: '100',
              }
            }>
              {client?.firstName ? client.firstName : ''} {client?.lastName ? client.lastName : ''}
            </h2>
            <p style={
              {
                fontWeight: '100',
                fontSize: '12px',
                marginTop: '0',
                marginBottom: '0',
              }
            }>
              UUID : {client?.uuid ? client.uuid : ''}
              <Button
                  ml={2}
                  mb={1}
                  size="xs"
                  onClick={() => {
                    navigator.clipboard.writeText(`${client?.uuid}`)
                    toast({
                      title: 'UUID copié !',
                      status: 'success',
                      duration: 1000,
                      isClosable: true,
                    })
                  }}
                >
                <CopyIcon color='primary'/>
              </Button>
              
            </p>
            <p style={
              {
                fontWeight: '100',
                fontSize: '12px',
                marginTop: '0',
                marginBottom: '0',
              }
            }>
              Téléphone : {client?.phoneNumber ? client.phoneNumber : ''}
              <Button
                  ml={2}
                  mb={1}
                  size="xs"
                  onClick={() => {
                    navigator.clipboard.writeText(`${client?.phoneNumber}`)
                    toast({
                      title: 'Téléphone copié !',
                      status: 'success',
                      duration: 1000,
                      isClosable: true,
                    })
                  }}
                >
                <CopyIcon color='primary'/>
              </Button>
              
            </p>
            <p style={
              {
                fontWeight: '100',
                fontSize: '14px',
                marginTop: '0',
                marginBottom: '0',
              }
            }>
                { location ? '📍 ' + location : ''}
            </p>

            <Badge colorScheme='orange' style={{marginTop: '10px'}}>{client?.role ? client.role : ''}</Badge>
            
            {/*<Button onClick={
              () => {
                call(client?.uuid)
              }
            }>
              <PhoneIcon style={{marginRight: '10px'}}/>
              Appeler
            </Button>*/}

          </VStack>
          <Tabs width='100%'>
            <TabList>
              <Tab>Infos personnelles</Tab>
              {/*<Tab>Demandes</Tab>*/}
            </TabList>
            <TabPanels>
              <TabPanel>
                <ClientPersonnalInfo/>
              </TabPanel>
              <TabPanel>
                <ClientTickets/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Grid>
    </Box>
    </>
  )
}