import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { paths } from './path'
import { selectors } from '../store/selectors'

const PrivateRoute = () => {
  const tokens = localStorage.getItem('tokens');
  
  const redirectAuth = () => {
    localStorage.setItem('lastPageBeforeLogin', window.location.pathname)
    return <Navigate to={paths.AUTH_LOGIN} />
  }

  if (tokens) {
    const parsedTokens = JSON.parse(tokens);

    if(parsedTokens.expiry <= new Date().getTime()) {
      localStorage.removeItem('tokens');
      return redirectAuth()
    }

    return (
      <Outlet />
    )
  }

  return redirectAuth()
}

export default PrivateRoute
