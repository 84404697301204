/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { request } from '../../http'

export default () =>
  async ({ data }) => {
    const { code } = data
    return request({
      route: `/auth/call-center-token/`,
      method: 'GET',
      headers: {},
      dataType: 'json',
      data: {},
      params: {
        code,
      },
    })
  }
