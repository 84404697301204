import { request } from '../../http'

const req = async ({ data, headers }) => 
  request({
    route: `/call-center/clients/${data.uuid}/call`,
    method: 'POST',
    headers,
    dataType: 'json',
    data: {},
  })

export default req

