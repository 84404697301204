import { Box, Stack, VStack } from "@chakra-ui/react"
import { Field, Form } from "react-final-form"
import { InputText } from "../../components/form"
import { InputTextarea } from "../../components/form/InputTextarea"

export const Ticket = (props) => {
  const ticket = props.ticket;

  function formatDate(date: Date) {
    const min = new Date(date).toLocaleTimeString('fr-fr', {
      minute: '2-digit',
    });
    const hour = new Date(date).toLocaleTimeString('fr-fr', {
      hour: '2-digit',
    });
    const day = new Date(date).toLocaleDateString('fr-fr', {
      day: '2-digit',
    });
    const month = new Date(date).toLocaleDateString('fr-fr', {
      month: '2-digit',
    });
    const year = new Date(date).toLocaleDateString('fr-fr', {
      year: 'numeric',
    });
    return `${day}/${month}/${year} - ${hour}${min}`

  }

  return (
    <>
      <VStack spacing={8} width='100%'>
      <Form
          onSubmit={(values) => {}}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} style={{width: '80%'}}>
              <Stack spacing={4}>
                <Field
                  name="date"
                  component={InputText}
                  label="Date"
                  initialValue={ticket?.createdAt ? formatDate(ticket.createdAt) : ''}
                  disabled
                />
                <Field
                  name="category"
                  component={InputText}
                  label="Catégorie"
                  initialValue={ticket?.category?.parentCategory ? ticket.category.parentCategory.title : ''}
                  disabled
                />
                <Field
                  name="subCategory"
                  component={InputText}
                  label="Sous-catégorie"
                  initialValue={ticket?.category ? ticket.category.title : ''}
                  disabled
                />
                <Box
                  as="h3"
                  textStyle="h3"
                  fontWeight={700}
                  color="primary.400"
                  textAlign="left"
                  fontSize={12}
                  mt={3}
                  mb={3}
                >
                  DEMANDE{' '}  
                </Box>
                <Field
                  name="content"
                  component={InputTextarea}
                  label="Objet"
                  defaultValue={ticket?.content ? ticket.content : ''}
                  disabled
                />
                
              </Stack>
            </form>
          )}
        />
      </VStack>
    </>
  )
}
