import { ArrowBackIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, EditIcon } from "@chakra-ui/icons"
import { Box, Button, Grid, HStack, Icon, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, useDisclosure, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { MdHome } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { IndexLogo } from "../../components/logo"
import { actions } from "../../store"
import { selectors } from "../../store/selectors"

export const HistoricalPage = () => {

  const dispatch = useDispatch()
  const calls = useSelector(selectors.calls.getSelector)
  const navigate = useNavigate()
  const [getSearch, setSearch] = useState('');
  const [getPage, setPage] = useState(1);
  const [getLastPage, setLastPage] = useState(1);

  useEffect(() => {
    dispatch(actions.calls.get.process({
      page: getPage,
      search: getSearch
    }))
  }, [dispatch, getSearch, getPage])
  
  useEffect(() => {
    if(calls?.meta?.totalPages) {
      setLastPage(Number(calls?.meta?.totalPages))
    }
  }, [calls])

  function handleSearchChange(e) {
    setSearch(e.target.value);
  }

  function getPreviousPage() {
    if(getPage === 1) {
      return
    }
    setPage(getPage - 1)
  }

  function getNextPage() {
    if(getPage === getLastPage) {
      return
    }
    setPage(getPage + 1)
  }

  function formatDate(date: Date) {
    const day = new Date(date).toLocaleDateString('fr-fr', {
      day: '2-digit',
    });
    const month = new Date(date).toLocaleDateString('fr-fr', {
      month: '2-digit',
    });
    const year = new Date(date).toLocaleDateString('fr-fr', {
      year: 'numeric',
    });
    const hours = new Date(date).toLocaleTimeString('fr-fr', {
      hour: '2-digit',
    });
    const minutes = new Date(date).toLocaleTimeString('fr-fr', {
      minute: '2-digit',
    });
    return `${day}/${month}/${year} - ${hours} ${minutes}`

  }

  function formatStatus(status: string) {
    switch(status) {
      case 'ringing':
        return 'Sonnerie'
      case 'ongoing':
        return 'En cours'
      case 'missed':
        return 'Manqué'
      case 'ended':
        return 'Terminé'
      default:
        return 'Inconnu'
    }
  }

  return (
    <>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3} bg='white'>
          <VStack>
            <Box width={"100%"} display='flex' height={50} paddingLeft={1} paddingTop={1} flexDirection={"row"} justifyContent={"flex-start"}>
              <>
                <IconButton
                  aria-label='GoHome'
                  variant='solid'
                  icon={<MdHome/>}
                  fontSize='3 0px'
                  size='lg'
                  onClick={()=>{navigate("/")}}
                />
              </>
            </Box>
            {/* <Input placeholder='Recherche' size='md' width={500} marginTop={50} marginBottom={50} value={getSearch} onChange={(e) => handleSearchChange(e)}/> */}
            <TableContainer width={'70%'}>
              <Table size='sm' >
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Client</Th>
                    <Th>Entrant/Sortant</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {calls && calls.data && calls.data.map((call) => {
                    return (
                      <Tr>
                        <Td>{formatDate(call.createdAt)}</Td>
                        <Td>{call.callerType === 'client' ? call.callerUser.fullName : call.calledUser.fullName}</Td>
                        <Td>{call.callerType === 'client' ? 'Entrant' : 'Sortant'}</Td>
                        <Td>{formatStatus(call.status)}</Td>
                      </Tr>
                    )
                  })} 
                </Tbody>
              </Table>
            </TableContainer>
            <div>
              <Button colorScheme='primary' padding={0} margin={0} borderRadius={0} borderLeftRadius={10} disabled={getPage === 1} onClick={getPreviousPage}><ChevronLeftIcon/></Button>
              <Button colorScheme='primary' padding={0} borderRadius={0}>{getPage}/{getLastPage}</Button>
              <Button colorScheme='primary' padding={0} borderRadius={0} borderRightRadius={10} disabled={getPage === getLastPage} onClick={getNextPage}><ChevronRightIcon/></Button>
            </div>
          </VStack>
        </Grid>
      </Box>
    </>
  )
}