import * as React from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { FaDownload } from '../../Svg'

export const InputFile = ({
  files,
  input,
  meta,
  placeholder,
  invalid,
  ...rest
}) => (
  <>
    <FormControl>
      {rest.label && <FormLabel>{rest.label} </FormLabel>}
      <InputGroup>
        <InputLeftElement color="primary.400">
          <FaDownload />
        </InputLeftElement>
        {/* <input
          type="file"
          //isInvalid={(meta.error || meta.submitError) && meta.touched}
          onChange={({ target }) => {
            console.log(target.files[0])
            console.log(input)
            if(input.onChange) {
              input.onChange(target.files[0])
            }
          }}
          style={{ paddingTop: '7px' }}
        /> */}
        <Input
          type="file"
          onChange={({ target }) => {
            console.log(target.files[0])
            console.log(input)
            if(input.onChange) {
              input.onChange(target.files[0])
            }
          }}
          style={{ paddingTop: '7px' }}
        />
      </InputGroup>
    </FormControl>
    {(meta.error || meta.submitError) && meta.touched && (
      <Box as="p" fontWeight="700" color="red.400" textAlign="left">
        {meta.error || meta.submitError}
      </Box>
    )}
  </>
)
