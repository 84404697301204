import { extendTheme, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import '@fontsource/spartan'
import '@fontsource/spartan/200.css'
import '@fontsource/spartan/700.css'
import '@fontsource/spartan/900.css'
import { menuAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

const theme = {
  primary: {
    50: '#EBF1F9',
    100: '#C4D5ED',
    200: '#9DBAE2',
    300: '#769ED6',
    400: '#284F85',
    500: '#3569B0',
    600: '#295289',
    700: '#1D3A62',
    800: '#12233B',
    900: '#060C14',
  },
  secondary: {
    50: '#ffb79a',
    100: '#ffad90',
    200: '#ffa386',
    300: '#ff997c',
    400: '#FE8568',
    500: '#fe8568',
    600: '#f47b5e',
    700: '#ea7154',
    800: '#e0674a',
    900: '#d65d40',
  },
  lightblue: {
    50: '#9ac3ff',
    100: '#90b9ff',
    200: '#86afff',
    300: '#7ca5ff',
    400: '#729bff',
    500: '#6891fb',
    600: '#5e87f1',
    700: '#547de7',
    800: '#4a73dd',
    900: '#4069d3',
  },
  pink: {
    50: '#ffe6f0',
    100: '#fabace',
    200: '#f18ead',
    300: '#ea628c',
    400: '#e3376b',
    500: '#c91d52',
    600: '#9d1540',
    700: '#710d2d',
    800: '#45051b',
    900: '#1d0009',
  },
  orange: {
    50: '#fff6dc',
    100: '#fee4b0',
    200: '#fcd382',
    300: '#fac151',
    400: '#f9af22',
    500: '#e0950b',
    600: '#ae7405',
    700: '#7c5301',
    800: '#4c3200',
    900: '#1d1000',
  },
  red: {
    50: '#ffe6e4',
    100: '#fababa',
    200: '#f18e8e',
    300: '#eb6361',
    400: '#e43735',
    500: '#ca1e1b',
    600: '#9e1614',
    700: '#720f0e',
    800: '#460607',
    900: '#1e0000',
  },
  corail: {
    50: '#ffb79a',
    100: '#ffad90',
    200: '#ffa386',
    300: '#ff997c',
    400: '#FE8568',
    500: '#fe8568',
    600: '#f47b5e',
    700: '#ea7154',
    800: '#e0674a',
    900: '#d65d40',
  },
  blue: {
    50: '#EBF1F9',
    100: '#C4D5ED',
    200: '#9DBAE2',
    300: '#769ED6',
    400: '#284F85',
    500: '#3569B0',
    600: '#295289',
    700: '#1D3A62',
    800: '#12233B',
    900: '#060C14',
  },
  green: {
    50: '#97f7db',
    100: '#8dedd1',
    200: '#83e3c7',
    300: '#79d9bd',
    400: '#6fcfb3',
    500: '#65c5a9',
    600: '#5bbb9f',
    700: '#51b195',
    800: '#47a78b',
    900: '#3d9d81',
  },
  lightBrown: {
    50: '#ffffff',
    100: '#ffffff',
    200: '#ffffff',
    300: '#fffff7',
    400: '#fefaed',
    500: '#f4f0e3',
    600: '#eae6d9',
    700: '#e0dccf',
    800: '#d6d2c5',
    900: '#ccc8bb',
  },
  black: {
    400: '#2D2D2D',
  },
  highLight: '#00C917',
  warning: '#FFC75F',
  danger: '#C34A36',
  lime: {
    50: '#f2ffde',
    100: '#defcb2',
    200: '#caf884',
    300: '#b5f554',
    400: '#a1f226',
    500: '#a1f226',
    600: '#69a905',
    700: '#4a7801',
    800: '#2b4800',
    900: '#0b1900',
  },
  paragraph: '#474A57',
}

// define the base component styles
const baseStyle = definePartsStyle({
  list: {
    // this will style the MenuList component
    py: '4',
    borderRadius: 'xl',
    border: 'none',
    bg: 'white',
  },
  item: {
    // this will style the MenuItem and MenuItemOption components
    color: 'primary.400',
    fontWeight: '900',
    marginLeft: 6,
    marginRight: 6,
    svg: {
      color: 'lightblue.400',
      fontSize: 50,
      marginRight: 6,
    },
    _hover: {
      bg: 'transparent',
      color: 'lightblue.400',
      svg: {
        color: 'white',
        circle: { fillOpacity: '1' },
      },
    },
    _focus: {
      bg: 'transparent',
      color: 'lightblue.400',
    },
  },
  divider: {
    borderColor: '#F4F0E3',
    borderBottom: '1px solid',
  }
})
// export the base styles in the component theme
export const menuTheme = defineMultiStyleConfig({ baseStyle })

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-30px)',
  backgroundColor: 'white',
  borderRadius: 4,
}

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const indexChakraTheme = extendTheme({
  fonts: {
    heading: `"Spartan", sans-serif`,
    body: `"Spartan", sans-serif`,
  },
  textStyles: {
    h1: {
      fontSize: ['25px', '25px', '25px'],
      fontWeight: '700',
      lineHeight: '100%',
      letterSpacing: '-2%',
    },
    h2: {
      fontSize: ['24px', '24px', '24px'],
      fontWeight: '900',
      lineHeight: '100%',
      letterSpacing: '-1%',
      color: theme.blue['400'],
    },
    h3: {
      fontSize: ['16px', '16px', '18px'],
      fontWeight: '600',
      lineHeight: '100%',
      letterSpacing: '-1%',
    },
    p: {
      fontSize: ['15px', '15px', '15px'],
      fontWeight: '500',
    },
    a: {
      fontSize: ['15px', '15px', '15px'],
      fontWeight: '700',
    },
  },
  styles: {
    global: () => ({
      'html, body': {
        color: theme.paragraph,
        background: '#2E2623',
      },
      'h1, h2, h3': {},
      '*, *::before, &::after': {
        wordWrap: 'break-word',
        // textDecoration: 'none !important',
        // transition: 'background 0.5s ease-in',
      },
      "input[disabled]": {
        opacity: '0.8 !important',
      },
      "textarea[disabled]": {
        opacity: '0.8 !important',
      },
    }),
  },
  config,
  colors: {
    ...theme,
  },
  components: {
    Menu: menuTheme,
    Button: {
      variants: {
        solid: () => ({
          fontWeight: '700',
          borderRadius: 50,
          boxShadow: `2px 4px 0px 0px rgb(0 0 0 / 8%);`,
          fontSize: 15,
          color: 'white',
        }),
      },
      defaultProps: {
        size: 'lg',
        colorScheme: 'primary',
      },
    },
    Input: {
      sizes: {
        lg: {
          field: {
            borderWidth: '4px',
            borderStyle: 'solid',
            // height: '56px',
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'primary.400',
        errorBorderColor: 'red.400',
        size: 'lg',
      },
      
    },
    PinInput: {
      sizes: {
        lg: {
          borderWidth: '4px',
          borderStyle: 'solid',
          h: 14,
          w: 14,
        },
      },
      defaultProps: {
        focusBorderColor: 'primary.400',
        errorBorderColor: 'red.400',
        size: 'lg',
      },
    },
    Select: {
      baseStyle: {
        icon: {
          color: 'primary.400',
        },
      },
      defaultProps: {
        focusBorderColor: 'primary.400',
        errorBorderColor: 'red.400',
      },
    },
    Form: {
      variants: {
        floating: {
          container: {

            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            input: {
              height: '56px',
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'transparent',
              pointerEvents: 'none',
              fontWeight: 400,
              mx: 3,
              px: 1,
              my: 4,
              transformOrigin: 'left top',
              color: 'rgba(54, 53, 55, 0.4)',
            },
          },
        },
      },
    },
    Alert: {
      variants: {
        toast_success: {
          container: {
            color: "gray.50",
            bg: theme.blue['400'],
          }
        }
      }
    }
  },
})

export { indexChakraTheme, theme }
