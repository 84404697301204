import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { reset as resetAction } from '../actions'
import get from './get'

import { reducers as reducerHelpers } from '../../utils'

const INITIAL_STATE = _.merge({}, get.initialState)

const actionsMap = _.merge({}, get.actionsMap, {
  [resetAction as any]: (state, action) =>
    reducerHelpers.resetState(state, INITIAL_STATE, action.payload.keys),
})

export default createReducer(INITIAL_STATE, actionsMap)
