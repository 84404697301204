import { Route } from "react-router-dom";
import { WaitingPage } from "../../views/WaitingPage";
import { paths } from "../path";
import PrivateRoute from "../privateRoute";

const routes = [
  <Route path={paths.HOME} element={<PrivateRoute />} children={[
    <Route path={paths.HOME} element={<WaitingPage />} />,
  ]} />,
]

export default routes