import { SVGProps } from 'react'

export const IndexLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 90 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_668_2199)">
        <path d="M60.8315 17.8942C58.8138 17.8942 57.2338 16.263 57.2338 14.3095C57.2338 12.299 58.8709 10.7247 60.8315 10.7247C61.5358 10.7247 62.1259 10.1368 62.1259 9.435C62.1259 8.73323 61.5358 8.14526 60.8315 8.14526C58.8138 8.14526 57.0625 9.11257 55.9394 10.573C54.8163 9.11257 53.008 8.14526 51.0473 8.14526C50.343 8.14526 49.7529 8.73323 49.7529 9.435C49.7529 10.1368 50.343 10.7247 51.0473 10.7247C53.0651 10.7247 54.645 12.3559 54.645 14.3095C54.645 16.3199 53.008 17.8942 51.0473 17.8942C50.343 17.8942 49.7529 18.4821 49.7529 19.1839C49.7529 19.8857 50.343 20.4737 51.0473 20.4737C53.0651 20.4737 54.8163 19.5063 55.9394 18.0459C57.0625 19.5063 58.8709 20.4737 60.8315 20.4737C61.5358 20.4737 62.1259 19.8857 62.1259 19.1839C62.1259 18.4442 61.5358 17.8942 60.8315 17.8942Z" fill="white"/>
        <path d="M27.2293 18.4821C25.1354 18.4821 23.4413 16.7751 23.4413 14.6888C23.4413 12.6024 25.1545 10.9144 27.2293 10.9144C29.3232 10.9144 31.0364 12.6024 31.0364 14.6888C31.0364 16.7941 29.3232 18.4821 27.2293 18.4821ZM32.4069 2C31.6455 2 31.0364 2.60694 31.0364 3.36561V9.39703C29.9704 8.63836 28.657 8.18316 27.2293 8.18316C23.6316 8.18316 20.7002 11.104 20.7002 14.6888C20.7002 18.2735 23.6316 21.2133 27.2293 21.2133C30.827 21.2133 33.7775 18.2924 33.7775 14.6888V3.36561C33.7775 2.60694 33.1493 2 32.4069 2Z" fill="white"/>
        <path d="M1.82172 7.93652C1.0603 7.93652 0.451172 8.54346 0.451172 9.30213V19.6011C0.451172 20.3597 1.0603 20.9667 1.82172 20.9667C2.58313 20.9667 3.19227 20.3597 3.19227 19.6011V9.30213C3.19227 8.54346 2.58313 7.93652 1.82172 7.93652Z" fill="white"/>
        <path d="M42.3851 10.6489C44.3648 10.6868 44.5551 11.9196 44.5741 12.0714C44.5932 12.3179 44.5551 12.4886 44.3838 12.6783C44.0412 13.0576 42.8039 13.9111 38.6161 14.0439C38.8255 12.1283 40.4435 10.6489 42.3851 10.6489ZM45.6972 17.0027C44.7074 17.7993 43.6033 18.1976 42.4041 18.1976C41.1859 18.1976 40.1008 17.6286 39.3965 16.7182C42.9371 16.5286 45.2404 15.7889 46.4206 14.4801C47.1059 13.7215 47.4104 12.7731 47.2962 11.7489C46.9726 8.71425 43.946 7.89868 42.3851 7.89868C38.7874 7.89868 35.8369 10.8196 35.8369 14.4232C35.8369 18.0269 38.7684 20.9478 42.3851 20.9478C44.1934 20.9478 45.9257 20.3219 47.4104 19.127C48.0005 18.6528 48.0957 17.7993 47.6198 17.2114C47.1439 16.6234 46.2873 16.5286 45.6972 17.0027Z" fill="white"/>
        <path d="M74.9946 17.9889C74.7852 17.9889 74.5948 18.0269 74.4426 18.1027C74.2712 18.1786 74.0999 18.2165 73.9096 18.2165C73.7382 18.2165 73.5669 18.1786 73.4146 18.1217C73.2624 18.0648 73.1481 17.951 73.053 17.7993C72.9578 17.6475 72.9197 17.4768 72.9197 17.2492V10.535H74.6519C75.0327 10.535 75.3563 10.4022 75.6227 10.1557C75.8892 9.90911 76.0035 9.58668 76.0035 9.22631C76.0035 8.84697 75.8702 8.52454 75.6227 8.259C75.3563 7.99347 75.0327 7.87967 74.6519 7.87967H72.9007V5.33813C72.9007 4.92086 72.7674 4.56049 72.5009 4.29495C72.2345 4.01045 71.8918 3.87769 71.473 3.87769C71.0543 3.87769 70.6926 4.01045 70.4261 4.29495C70.1406 4.57946 70.0073 4.92086 70.0073 5.33813V7.8607H68.8842C68.5035 7.8607 68.1799 7.99347 67.9134 8.24004C67.6469 8.48661 67.5327 8.80904 67.5327 9.16941C67.5327 9.54874 67.666 9.87118 67.9134 10.1367C68.1799 10.4022 68.5035 10.516 68.8842 10.516H70.0073V17.363C70.0073 18.1596 70.1215 18.7666 70.369 19.2218C70.6164 19.677 70.921 19.9994 71.2827 20.227C71.6444 20.4357 72.0441 20.5874 72.4438 20.6443C72.8626 20.7012 73.2243 20.7202 73.5669 20.7202C74.138 20.7202 74.671 20.5684 75.1659 20.2839C75.6608 19.9994 75.9083 19.6201 75.9083 19.1649C75.9083 18.7856 75.8131 18.501 75.6227 18.2924C75.4324 18.1027 75.223 17.9889 74.9946 17.9889Z" fill="white"/>
        <path d="M79.2377 7.78467C79.5423 7.78467 79.8278 7.86054 80.0943 8.01227C80.3608 8.164 80.5512 8.3916 80.6844 8.69507L84.2631 16.8318L83.7301 17.0784L87.3658 8.73301C87.6323 8.08814 88.0701 7.78467 88.6602 7.8226C89.06 7.8226 89.3836 7.95537 89.6501 8.20194C89.8975 8.4485 90.0308 8.77094 90.0308 9.13131C90.0308 9.24511 90.0117 9.35891 89.9737 9.49168C89.9356 9.62444 89.8975 9.73824 89.8404 9.85204L85.2719 19.8096C85.0054 20.3976 84.6057 20.701 84.0346 20.72C83.7301 20.7769 83.4255 20.72 83.14 20.5493C82.8544 20.3976 82.645 20.132 82.4928 19.7906L77.9814 9.81411C77.9433 9.73824 77.9052 9.62444 77.8672 9.49168C77.8291 9.35891 77.8101 9.20717 77.8101 9.03647C77.8101 8.75197 77.9433 8.46747 78.1908 8.18297C78.4573 7.9364 78.7999 7.78467 79.2377 7.78467Z" fill="white"/>
        <path d="M12.1771 7.93652C12.12 7.93652 12.0629 7.93652 11.9867 7.93652H12.1771C11.054 7.93652 10.007 8.22103 9.07431 8.71416C9.05527 8.71416 9.05527 8.73313 9.03624 8.73313C8.88395 8.82796 8.71264 8.92279 8.56035 9.01763C8.54132 9.0366 8.50325 9.05556 8.48421 9.07453C6.77103 10.2315 5.64794 12.1851 5.62891 14.4042V14.4611V19.62C5.62891 20.3787 6.23804 20.9856 6.99945 20.9856C7.76087 20.9856 8.37 20.3787 8.37 19.62V14.4611C8.37 12.3747 10.0832 10.6867 12.158 10.6867C14.2329 10.6867 15.9461 12.3747 15.9461 14.4611V19.62C15.9461 20.3787 16.5552 20.9856 17.3166 20.9856C18.078 20.9856 18.6872 20.3787 18.6872 19.62V14.4611C18.7252 10.8574 15.7748 7.93652 12.1771 7.93652Z" fill="white"/>
        <path d="M1.82154 2.30347C0.831697 2.30347 -0.00585938 3.138 -0.00585938 4.16221C-0.00585938 5.18641 0.831697 5.98302 1.82154 5.98302C2.84945 5.98302 3.64893 5.14848 3.64893 4.16221C3.64893 3.17594 2.84945 2.30347 1.82154 2.30347Z" fill="white"/>
        <path d="M66.4085 17.2302C65.4186 17.2302 64.5811 18.0648 64.5811 19.1079C64.5811 20.1511 65.4186 20.9287 66.4085 20.9287C67.4364 20.9287 68.2358 20.0942 68.2358 19.1079C68.2358 18.1217 67.4364 17.2302 66.4085 17.2302Z" fill="white"/>
      </g>
    </svg>
  )
}