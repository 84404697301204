import { request } from '../../http'

const req = async ({ data, headers }) => 
  request({
    route: `/call-center/tickets/${data.clientUuid}/?sortBy=createdAt:DESC}`,
    method: 'GET',
    headers,
    dataType: 'json',
    data: {},
  })

export default req
  
