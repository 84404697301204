import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "../../store/selectors";
import { actions } from "../../store"
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, VStack } from "@chakra-ui/react";
import { IndexLogo } from "../../components/logo";

export const Login = () => {

  const dispatch = useDispatch();
  const loginUrl = useSelector(selectors.loginUrl.getSelector);
  const navigate = useNavigate();
  const [externalPopup, setExternalPopup] = useState(null);
  
  useEffect(() => {
    dispatch(actions.loginUrl.get.process())
  }, [dispatch]);

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const handleMessage = (event) => {
      const origin = event.origin.endsWith('/') ? event.origin : `${event.origin}/`;
      const front = process.env.REACT_APP_FRONT_URL.endsWith('/') ? process.env.REACT_APP_FRONT_URL : `${process.env.REACT_APP_FRONT_URL}/`;

      if (!origin.startsWith(front)){
        return; 
      } 

      if (!event.data.tokens) {
        return;
      }
    
      try {
        const parsedTokens = JSON.parse(event.data.tokens);
        if (parsedTokens && parsedTokens.accessToken) {
          const obj = {
            accessToken: parsedTokens.accessToken,
            expiry: new Date().getTime() + 86400000, // 24 hours
          };
          localStorage.setItem('tokens', JSON.stringify(obj));
          window.removeEventListener('message', handleMessage);
          externalPopup.close();
          // Refresh the page
          setTimeout(() => {
            navigate("/")
          }, 1000)
        }
      } catch (e) {
        console.error('Failed to parse tokens:', e);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [externalPopup]);

  const handleLogin = () => {
    if(loginUrl && !externalPopup) {
      const popup = window.open(loginUrl, '_blank', 'width=600,height=600');
      setExternalPopup(popup);
      //window.location.href = loginUrl;
    }
  }
  
  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" pt={80} >
        <VStack mt={20} gap={4}>
          <IndexLogo width={'100%'} height={'100px'} />
          <Button colorScheme='blue' width={500} mt={2} onClick={handleLogin}>Connexion</Button>
        </VStack>
      </Grid>
    </Box>
  )
}