import { Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { WaitingPage } from "../views/WaitingPage";
import { routes } from "./routes";
import { Flex } from "@chakra-ui/react";
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const Main = () => {
  return (
    <Sentry.ErrorBoundary fallback={
      <WaitingPage />
    } >
      <Flex flexDirection="column">
        <SentryRoutes>
          {routes}
        </SentryRoutes>
      </Flex>
    </Sentry.ErrorBoundary>
  )
}