import { Route } from "react-router-dom";
import { Client } from "../../views/Clients/Client";
import { ClientPage } from "../../views/Clients/ClientPage";
import { ClientList } from "../../views/Clients/List";
import { paths } from "../path";
import PrivateRoute from "../privateRoute";

const routes = [
  <Route path={paths.CLIENT} element={<PrivateRoute />} children={[
    <Route path={paths.CLIENT} element={<ClientPage />} />,
  ]} />,
  <Route path={paths.CLIENT_WITH_CITY} element={<PrivateRoute />} children={[
    <Route path={paths.CLIENT_WITH_CITY} element={<ClientPage />} />,
  ]} />,
  <Route path={paths.CLIENTS_LIST} element={<PrivateRoute />} children={[
    <Route path={paths.CLIENTS_LIST} element={<ClientList />} />,
  ]} />,
]

export default routes