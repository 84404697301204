import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { reducer } from './reducer'
import { rootSaga } from './rootSagas'

const devMode = process.env.NODE_ENV === 'development'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
  key: 'INDEX_STORAGE',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: devMode,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }),
    sagaMiddleware
  ],
})
sagaMiddleware.run(rootSaga)

const persistor = persistStore(store, null)

export { store, persistor }

export * as actions from './actions'
