import { Client } from "./Client"

export const ClientPage = () => {
  const { pathname } = window.location
  const uuid = pathname.split('/')[2]
  const location = pathname.split('/').length > 3 ? pathname.split('/')[3] : 'Localisation inconnue'
  return (
    <>
        <Client uuid={uuid} location={location} />
    </>
  )
}