import { request } from '../../http'

const req = async ({ data, headers }) => 
  request({
    route: `/call-center/clients/calls?sortBy=createdAt:DESC&limit=10&page=${data.page ? data.page : 1}`,
    method: 'GET',
    headers,
    dataType: 'json',
    data: {},
  })

export default req

