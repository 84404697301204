import { SearchIcon } from "@chakra-ui/icons"
import { Button, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Stat, StatHelpText, StatLabel, StatNumber, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, useDisclosure, useToast, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../store"
import { selectors } from "../../store/selectors"
import { AddTicket } from "./AddTicket"
import { Ticket } from "./Ticket"

export const ClientTickets = () => {

  const dispatch = useDispatch()
  const tickets = useSelector(selectors.tickets.getSelector)
  const client = useSelector(selectors.client.getSelector)

  const toast = useToast({
    variant: "toast_success",
  })

  useEffect(() => {
    if(client) {
      dispatch(actions.tickets.get.process({clientUuid: client.uuid}))
    }
  }, [dispatch, client])

  function formatDate(date: Date) {
    const day = new Date(date).toLocaleDateString('fr-fr', {
      day: '2-digit',
    });
    const month = new Date(date).toLocaleDateString('fr-fr', {
      month: '2-digit',
    });
    const year = new Date(date).toLocaleDateString('fr-fr', {
      year: 'numeric',
    });
    return `${day}/${month}/${year}`

  }

  const addModalDisclosure = useDisclosure()

  const readModalDisclosure = useDisclosure()
  const [readingTicket, setReadingTicket] = useState(null);

  function openReadModal(ticket) {
    setReadingTicket(ticket);
    readModalDisclosure.onOpen()
  }

  function onSuccessfulAdd() {
    addModalDisclosure.onClose()
    toast({
      description: "Votre demande a bien été ajoutée",
      status: "success",
      duration: 5000,
      isClosable: true,
    })
    if(client) {
      dispatch(actions.tickets.get.process({clientUuid: client.uuid}))
    }
  }

  return (
    <>
      <Modal isOpen={addModalDisclosure.isOpen} onClose={addModalDisclosure.onClose} size='full'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter une demande</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddTicket submitCallBack={onSuccessfulAdd}/>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='primary' mr={3} onClick={addModalDisclosure.onClose}>Fermer</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={readModalDisclosure.isOpen} onClose={readModalDisclosure.onClose} size='full'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Demande client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Ticket ticket={readingTicket}/>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='primary' mr={3} onClick={readModalDisclosure.onClose}>Fermer</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <VStack width='100%'>

        <Button colorScheme='primary' variant='solid' size='sm' onClick={addModalDisclosure.onOpen}>Ajouter une demande</Button>

        <TableContainer width={'100%'} overflowX='hidden'>
          <Table variant='simple' size='sm' overflowX='hidden'>
            <TableCaption>Liste des demandes du client</TableCaption>
            <Thead >
              <Tr>
                <Th>Demande</Th>
                <Th>Catéorie</Th>
                <Th>Sous Categorie</Th>
                <Th>Date de la demande</Th>
                <Th>Détails</Th>
              </Tr>
            </Thead>
            <Tbody>
              { tickets && tickets.data ? tickets.data.map((ticket) => 
                <Tr>
                  <Td>{ ticket.content ? ( ticket.content.length > 19 ? ticket.content.slice(0, 20)+'...': ticket.content) : '' }</Td>
                  <Td>{ ticket.category && ticket.category.parentCategory ? ticket.category.parentCategory.title : '' }</Td>
                  <Td>{ ticket.category && ticket.category.parentCategory ? ticket.category.title : '' }</Td>
                  <Td>{ ticket.createdAt ? formatDate(ticket.createdAt) : ''}</Td>
                  <Td><IconButton aria-label='Search database' isRound={false} size='sm' icon={<SearchIcon />} onClick={(e) => openReadModal(ticket)}/></Td>
                </Tr>
              ) : <></>}
            </Tbody>
            <Tfoot>

            </Tfoot>
          </Table>
        </TableContainer>
      </VStack>
    </>
  )
}