import { Route } from "react-router-dom";
import { HistoricalPage } from "../../views/Calls/HistoricalPage";
import { paths } from "../path";
import PrivateRoute from "../privateRoute";

const routes = [
  <Route path={paths.CALLS} element={<PrivateRoute />} children={[
    <Route path={paths.CALLS} element={<HistoricalPage />} />,
  ]} />
]

export default routes